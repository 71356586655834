import Vue from 'vue'
import Router from 'vue-router'

const HomePage = () => import(/* webpackChunkName: "homePage" */ '@/pages/HomePage.vue');
const UAPage = () => import(/* webpackChunkName: "UA" */ '@/pages/UAPage.vue');
const AboutPage = () => import(/* webpackChunkName: "AboutPage" */ '@/pages/AboutPage.vue');
const ContactsPage = () => import(/* webpackChunkName: "ContactsPage" */ '@/pages/ContactsPage.vue');
const CasesPage = () => import(/* webpackChunkName: "CasesPage" */ '@/pages/CasesPage.vue');
const Page404 = () => import(/* webpackChunkName: "CasesPage" */ '@/pages/Page404.vue');

Vue.use(Router);

const routes = [
    {
        path: '/',
        component: {
            template: '<router-view></router-view>',
        },

        children: [
            {
                path: '',
                name: 'HomePage',
                component: HomePage,
            },
            {
                path: 'ua',
                name: 'UAPage',
                component: UAPage,
            },
            {
                path: 'about',
                name: 'AboutPage',
                component: AboutPage,
            },
            {
                path: 'contacts',
                name: 'ContactsPage',
                component: ContactsPage,
            },
            {
                path: 'cases',
                name: 'CasesPage',
                component: CasesPage,
            },
            {
                path: '*',
                name: 'NotFound',
                component: Page404,
            },
        ],
    },
];

const router = new Router({
        mode: 'history',
        routes,
        scrollBehavior(to) {
            if (to.hash) {
                return { selector: to.hash };
            }
            return {
                x: 0,
                y: 0,
                behavior: 'smooth'
            };
        },
    });

export default router;

