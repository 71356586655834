<template>
  <div id="app">
    <NavBar/>
    <Header/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>

import Header from "@/components/Header";
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {
    Footer,
    NavBar,
    Header
  }
}
</script>

<style lang="scss">

#app {

  @media (min-width: $screen-l) {
    background-image: url(./assets/images/main-bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    animation: animatedBackground 100s linear infinite;
    @keyframes animatedBackground {
      from {
        background-position: top right -670px;
      }

      25% {
        background-position: top 400px right 70px;
      }

      50% {
        background-position: top 800px right -670px;
      }

      75% {
        background-position: top 400px right -1670px;
      }

      to {
        background-position: top right -670px;
      }
    }
  }
}


</style>
