<template>
<header class="Header">
  <img :src="require(`@/assets/images/${logo}`)" alt="logo" class="Header-Logo">
</header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      logo: "logo.svg"
    }
  }
}
</script>

<style scoped lang="scss">
.Header {
  padding: 273px 0;
  background-image: url(../assets/images/header-bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &-Logo {
    display: block;
    margin: 0 auto;
  }
}
</style>