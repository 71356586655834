<template>
  <footer class="Footer">
    <div class="Container">
      <div class="Footer-Contents">
        <div class="Footer-Content Footer-Content--left">
          <router-link class="Footer-Link" to="/" @click.native="scrollToTop">Home</router-link>
          <router-link class="Footer-Link" to="/about">ABOUT</router-link>
        </div>
        <div class="Footer-Content Footer-Content--center">
          <img :src="require(`@/assets/images/${logo}`)" alt="logo" class="Footer-Logo">
          <div class="Footer-Copy">© 2024</div>
        </div>
        <div class="Footer-Content Footer-Content--right">
          <router-link class="Footer-Link" to="/ua">UA</router-link>
          <router-link class="Footer-Link" to="/contacts">CONTACTS</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      logo: "logo-footer.svg"
    }
  },
  methods: {
    scrollToTop(e) {
      if (!e.target) {
        return
      }

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }
}
</script>

<style scoped lang="scss">

.Footer {
  padding: 120px 0;
  background-color: var(--color-footer-bg);

  &-Contents {
    display: flex;
    align-items: center;
    flex-direction: column;
    //justify-content: space-between;

    @media (min-width: $screen-l) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }
  }

  &-Content {
    font-size: 22px;
    line-height: 1.1em;
    color: var(--color-text-main3);
    display: flex;
    flex-direction: column;
  }

  &-Logo {
    margin-bottom: 30px;
    @media (min-width: $screen-l) {
      margin-bottom: 60px;
    }
  }

  &-Content--left {
    text-align: center;
    @media (min-width: $screen-l) {
      text-align: left;
    }
  }

  &-Content--center {
    order: 3;
    text-align: center;
    @media (min-width: $screen-l) {
      order: 0;
    }
  }

  &-Content--right {
    margin-bottom: 40px;
    text-align: center;

    @media (min-width: $screen-l) {
      text-align: right;
      margin-bottom: 0;
    }
  }

  &-Link {
    margin-bottom: 38px;
    text-transform: uppercase;

    @media (min-width: $screen-l) {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

</style>
