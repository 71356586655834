<template>
  <div class="NavBar">
    <nav class="Nav" :class="{ 'Nav--bg': documentIsScrolled }"
         v-if="width > 960"
    >
      <router-link
          class="Nav-Item"
          v-for="page in pages"
          :key="page.id"
          :to="page.link">{{ page.name }}
      </router-link>
    </nav>
    <button v-if="width < 960"
            class="NavBtn"
            @click.prevent="toggleNav">
      <span></span>
      <span></span>
      <span></span>
    </button>
    <transition v-if="width < 960" name="slide-right">
      <div class="NavMobile"
           v-show="navIsOpen"
      >
        <div class="Close"
             @click.prevent="toggleNav"
        >
          <span></span>
          <span></span>
        </div>
        <router-link
            @click.native="toggleNav"
            class="Nav-Item"
            v-for="page in pages"
            :key="page.id"
            :to="page.link">{{ page.name }}
        </router-link>
      </div>
    </transition>
  </div>
</template>

<script>
//import checkWidth from "@/scripts/script";

export default {
  name: "NavBar",
  data() {
    return {
      documentIsScrolled: false,
      pages: [
        {link: "/", name: "home"},
        {link: "/about", name: "about"},
        {link: "/ua", name: "ua"},
        {link: "/cases", name: "cases"},
        {link: "/contacts", name: "contacts"},
      ],
      width: 0,
      navIsOpen: false,
    }
  },
  mounted() {
    if (window.scrollY > 0) this.documentIsScrolled = true;
    window.addEventListener('scroll', this.onScroll, {passive: true});
    this.width = window.innerWidth;
  },
  methods: {
    onScroll() {
      this.documentIsScrolled = window.scrollY > 0;
    },
    toggleNav() {
      if (this.navIsOpen) {
        this.navIsOpen = false;
      }
      else {
        this.navIsOpen = true;
      }
    },
  },
}
</script>

<style scoped lang="scss">
.NavBar {
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;

  @media (min-width: $screen-l) {

  }
}

.Nav {
  padding: 49px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 22px;
  text-transform: uppercase;
  color: var(--color-text-main3);


  &-Item {
    margin-bottom: 40px;
    @media (min-width: $screen-l) {
      margin-bottom: 0;
      margin-right: 40px;
      position: relative;
      transition: .3s;

      &:hover {
        color: var(--color-text-light);
      }

      &:last-child {
        margin-right: 0;

        &:after {
          display: none;
        }
      }
      &:after {
        content: '';
        position: absolute;
        width: 4px;
        height: 4px;
        background: var(--color-text-main3);
        top: 50%;
        right: -22px;
        transform: translate(0, -50%);
        border-radius: 50%;
      }
    }
  }

  .router-link-exact-active {
    color: var(--color-text-light);
  }

  &--bg {
    background: rgba(0, 0, 0, 0.3);
    transition: .3s;
  }
}

.NavBtn {
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--color-text-light);
  border-radius: 50%;

  position: absolute;
  top: 20px;
  right: 20px;

  span {
    display: block;
    width: 30px;
    height: 4px;
    background-color: var(--color-text-light);
  }

  span + span {
    margin-top: 4px;
  }
}

.NavMobile {
  //position: -webkit-sticky;
  //position: sticky;
  position: fixed;
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-text-lighter);

  font-family: 'Lexend', sans-serif;
  font-weight: 500;
  font-size: 32px;
  text-transform: uppercase;
  transform: translate(0, 0);

  .router-link-exact-active {
    text-decoration: underline;
  }
}

.slide-right-enter-active, .slide-right-leave-active {
  transition: all .5s ease;
}

.slide-right-enter, .slide-right-leave-to {
  transform: translate(100%, 0);
  opacity: 0;
}

.Close {
  span {
    width: 20px;
    height: 2px;
    position: absolute;
    top: 40px;
    right: 40px;
    background-color: var(--color-text-main);
  }

  span:nth-child(1) {
    transform: rotateZ(-45deg);
  }

  span:nth-child(2) {
    transform: rotateZ(45deg);
  }
}
</style>